import React, { useState } from "react";
import styles from "./user.module.css";
import profile from "../../../assets/face1.jpg";
import { useSelector } from "react-redux";

const User = () => {
  const { theme } = useSelector((state) => state.theme);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    language: "English",
    role: "Time reporter",
  });

  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.firstName) errors.firstName = "First name can't be blank";
    if (!formData.lastName) errors.lastName = "Last name can't be blank";
    if (!formData.email) errors.email = "Email can't be blank";
    return errors;
  };

  const handleAddUser = () => {
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      console.log("User added", formData);
      setIsModalOpen(false);
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <>
      <style>
        {`
      .input {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
   border: ${theme === "dark" ? "1px solid #ffffff" : "1px solid #000000"};
  box-sizing: border-box;
  background-color: transparent;
  option{
     color: #000000; 
    }
}
      `}
      </style>

      <div
        className={`${styles.outlet} ${
          theme === "dark" ? "text-white" : "text-black"
        }`}
      >
        <h1 className="py-4 text-4xl flex items-center justify-center">
          Employees
        </h1>
        <button
          className="mb-5 heroButton"
          onClick={() => setIsModalOpen(true)}
        >
          + New User
        </button>

        {isModalOpen && (
          <div className={styles.modalOverlay}>
            <div
              className={`${styles.modal} ${
                theme === "dark"
                  ? "bg-[#212529] text-white"
                  : "bg-[#e5e5e5] text-black"
              }`}
            >
              <div className={styles.modalHeader}>
                <h2>Add new user</h2>
                <button
                  className={styles.closeButton}
                  onClick={() => setIsModalOpen(false)}
                >
                  &times;
                </button>
              </div>
              <form>
                <div className={styles.formGroup}>
                  <label>First name</label>
                  <input
                    className="input"
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                  />
                  {formErrors.firstName && (
                    <span className={styles.error}>{formErrors.firstName}</span>
                  )}
                </div>

                <div className={styles.formGroup}>
                  <label>Last name</label>
                  <input
                    className="input"
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                  />
                  {formErrors.lastName && (
                    <span className={styles.error}>{formErrors.lastName}</span>
                  )}
                </div>

                <div className={styles.formGroup}>
                  <label>E-Mail</label>
                  <input
                    className="input"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  {formErrors.email && (
                    <span className={styles.error}>{formErrors.email}</span>
                  )}
                </div>

                <div className={styles.formGroup}>
                  <label>Password</label>
                  <input
                    className="input"
                    type="email"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                </div>

                <div className={styles.formGroup}>
                  <label>Language</label>
                  <select
                    className="input"
                    name="language"
                    value={formData.language}
                    onChange={handleInputChange}
                  >
                    <option value="English">English</option>
                    <option value="Spanish">Spanish</option>
                  </select>
                </div>

                <div className={styles.formGroup}>
                  <label>User role</label>
                  <select
                    className="input"
                    name="role"
                    value={formData.role}
                    onChange={handleInputChange}
                  >
                    <option value="Time reporter">Time reporter</option>
                    <option value="Admin">Admin</option>
                  </select>
                </div>

                <div className={styles.actions}>
                  <button
                    type="button"
                    className="heroButton w-full"
                    onClick={handleAddUser}
                  >
                    ADD
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        <div
          className={`${styles.cardBody} ${
            theme === "dark" ? "bg-darkMode" : "bg-lightMode"
          }`}
        >
          <h4 className={styles.cardTitle}>Empolyee Table</h4>
          <div className={styles.tableResponsive}>
            <table className={styles.table + " " + styles.tableStriped}>
              <thead>
                <tr>
                  <th>Empolyee</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Number</th>
                  <th>Joining Date</th>
                  <th>Role</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="py-1">
                    <img src={profile} alt="user icon" className={styles.img} />
                  </td>
                  <td>Hamza</td>
                  <td>htariq@gmail.com</td>
                  <td>+923244030480</td>
                  <td>April 1, 2024</td>
                  <td>Developer</td>
                </tr>
                <tr>
                  <td className="py-1">
                    <img src={profile} alt="user icon" className={styles.img} />
                  </td>
                  <td>Hamza</td>
                  <td>htariq@gmail.com</td>
                  <td>+923244030480</td>
                  <td>April 1, 2024</td>
                  <td>Developer</td>
                </tr>
                <tr>
                  <td className="py-1">
                    <img src={profile} alt="user icon" className={styles.img} />
                  </td>
                  <td>Hamza</td>
                  <td>htariq@gmail.com</td>
                  <td>+923244030480</td>
                  <td>April 1, 2024</td>
                  <td>Developer</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default User;
