import React, { useState, useEffect, useRef } from "react";
import { FiBell, FiSettings, FiLogOut } from "react-icons/fi";
import { FaWindows, FaApple, FaLinux, FaDownload } from "react-icons/fa";
import profile from "../../../assets/face1.jpg";
import { NavLink } from "react-router-dom";
import styles from "./navbar.module.css";
import { IoHourglassOutline } from "react-icons/io5";
import { useSelector } from "react-redux";

const Navbar = () => {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const dropdownRef = useRef(null);
  const { theme } = useSelector((state) => state.theme);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActiveDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = (dropdown) => {
    setActiveDropdown((prev) => (prev === dropdown ? null : dropdown));
  };

  return (
    <div
      className={`${styles.navbarWrapper}  ${
        theme === "dark"
          ? "bg-darkMode text-white"
          : "bg-lightMode text-black drop-shadow-md"
      }`}
    >
      <div className={styles.menuContainer} ref={dropdownRef}>
        <div className={styles.dropdownWrapper}>
          <button
            onClick={() => toggleDropdown("download")}
            className={`${styles.menuItem} ${styles.iconButton}`}
          >
            <FaDownload />
          </button>
          {activeDropdown === "download" && (
            <div className={styles.dropdownContent}>
              <h6 className={styles.dropdownTitle}>Downloads</h6>
              <p className={styles.notificationText}>
                <FaWindows /> Windows Agent
              </p>
              <p className={styles.notificationText}>
                <FaApple /> Mac OS Agent
              </p>
              <p className={styles.notificationText}>
                <FaLinux /> Linux Agent
              </p>
            </div>
          )}
        </div>
        <div className={styles.dropdownWrapper}>
          <button
            onClick={() => toggleDropdown("notifications")}
            className={`${styles.menuItem} ${styles.iconButton}`}
          >
            <FiBell />
            <span className={styles.notificationCount} />
          </button>
          {activeDropdown === "notifications" && (
            <div className={styles.dropdownContent}>
              <h6 className={styles.dropdownTitle}>Notifications</h6>
              <p className={styles.notificationText}>Up coming</p>
            </div>
          )}
        </div>
        <div className={styles.dropdownWrapper}>
          <div
            className={styles.profile}
            onClick={() => toggleDropdown("profile")}
          >
            <img className={styles.profileImg} src={profile} alt="Profile" />
            <span className={styles.profileName}>Hamza Tariq</span>
          </div>
          {activeDropdown === "profile" && (
            <div className={styles.dropdownContent}>
              <h6 className={styles.dropdownTitle}>Profile</h6>
              <div className={styles.dropdownItem}>
                <NavLink
                  to="/dashboard/setting/preferences"
                  className={styles.dropdownItem}
                >
                  <IoHourglassOutline /> Preferences
                </NavLink>
              </div>
              <div className={styles.dropdownItem}>
                <NavLink
                  to="/dashboard/setting/hourlyrate"
                  className={styles.dropdownItem}
                >
                  <IoHourglassOutline /> Hourly Rate
                </NavLink>
              </div>
              <div className={styles.dropdownItem}>
                <NavLink
                  to="/dashboard/setting/personalinformation"
                  className={styles.dropdownItem}
                >
                  <FiSettings />
                  <span>Settings</span>
                </NavLink>
              </div>
              <div className={styles.dropdownItem}>
                <NavLink to="#" className={styles.dropdownItem}>
                  <FiLogOut />
                  <span>Log Out</span>
                </NavLink>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
