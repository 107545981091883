import React, { useState } from 'react';
import styles from './preferences.module.css';
import { useSelector } from 'react-redux';

const Preferences = () => {
  const {theme} = useSelector((state)=>state.theme)
  const [weekStart, setWeekStart] = useState('Sunday');
  const [language, setLanguage] = useState('English');

  const handleUpdate = () => {
    console.log('Preferences updated:', { weekStart, language });
  };

  const handleCancel = () => {
    setWeekStart('Sunday');
    setLanguage('English');
  };
 
  return (
    <div className= {`${styles.container} ${theme === 'dark' ? 'bg-darkMode' : 'bg-lightMode'}`}>
      <div className={`${styles.formGroup} ${theme === 'dark' ? 'text-white' : 'text-black'}`}>
        <label className={styles.label}>
          Calendar
        </label>
        <div className={styles.selectGroup}>
          <span className={styles.subLabel}>Week Start:</span>
          <select
            className={styles.select}
            value={weekStart}
            onChange={(e) => setWeekStart(e.target.value)}
          >
            <option value="Sunday">Sunday</option>
            <option value="Monday">Monday</option>
          </select>
        </div>
        <div className={styles.selectGroup}>
          <span className={styles.subLabel}>Language:</span>
          <select
            className={styles.select}
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
          >
            <option value="English">English</option>
            <option value="Spanish">Spanish</option>
            <option value="French">French</option>
          </select>
        </div>
      </div>

      <div className={`${styles.buttonGroup} w-full`}>
        <button className='heroButton' onClick={handleUpdate}>Update</button>
      </div>
    </div>
  );
};

export default Preferences;
