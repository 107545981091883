import React, { useEffect, useState } from "react";
import {
  FaArrowLeft,
  FaArrowRight,
  FaMouse,
  FaRegLightbulb,
  FaFilter,
  FaKeyboard,
} from "react-icons/fa";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { VscGraph } from "react-icons/vsc";
import styles from "./activity.module.css";
import OfflineActivityForm from "./OfflineActivityForm";
import FilterModal from "../filterModal/FilterModal";
import user from "../../../store";
import PreviewModal from "../previewModal/PreviewModal";
import img from '../../../assets/loginBg.png'
import { useSelector } from "react-redux";

const Activity = () => {
  const { theme } = useSelector((state) => state.theme);
  const role = "admin";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [days, setDays] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [showOfflineActivity, setShowOfflineActivity] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const joiningDate = new Date(user.joiningDate);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    generateCalendar(currentDate);
  }, [currentDate]);

  const generateCalendar = (date) => {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const lastDayOfPrevMonth = new Date(
      date.getFullYear(),
      date.getMonth(),
      0
    ).getDate();

    const daysInMonth = [];
    for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
      daysInMonth.push({
        day: i,
        currentMonth: true,
      });
    }

    for (let i = firstDayOfMonth.getDay() - 1; i >= 0; i--) {
      daysInMonth.unshift({
        day: lastDayOfPrevMonth - i,
        currentMonth: false,
      });
    }

    const totalDays = daysInMonth.length;
    for (let i = 1; totalDays + i <= 35; i++) {
      daysInMonth.push({
        day: i,
        currentMonth: false,
      });
    }

    setDays(daysInMonth);
  };

  const goToPrevMonth = () => {
    const prevMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      1
    );
    if (
      prevMonth.getFullYear() > joiningDate.getFullYear() ||
      (prevMonth.getFullYear() === joiningDate.getFullYear() &&
        prevMonth.getMonth() >= joiningDate.getMonth())
    ) {
      setCurrentDate(prevMonth);
    }
  };

  const goToNextMonth = () => {
    const nextMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );
    const today = new Date();
    if (nextMonth <= today) {
      setCurrentDate(nextMonth);
    }
  };

  const renderCalendarRows = () => {
    const rows = [];
    for (let i = 0; i < days.length; i += 7) {
      rows.push(days.slice(i, i + 7));
    }
    return rows;
  };

  const getSelectableMonths = () => {
    const selectableMonths = [];
    const currentYear = currentDate.getFullYear();
    const currentMonth = new Date().getMonth();

    for (let month = joiningDate.getMonth(); month <= currentMonth; month++) {
      selectableMonths.push(new Date(currentYear, month, 1));
    }

    return selectableMonths;
  };

  const handleMonthSelect = (month) => {
    setCurrentDate(month);
    setIsDropdownOpen(false);
  };

  return (
    <div className={`${styles.outlet} ${theme === 'dark' ? 'text-white' : 'text-black'}`}>
      {isModalOpen && <FilterModal onClose={closeModal} />}
      <div className={styles.container}>
        <div className={styles["calendar-header"]}>
          <div className={styles.user_main}>
            <span className={styles["month-div"]}>
              <FaArrowLeft onClick={goToPrevMonth} />
              <h2>
                {currentDate.toLocaleString("default", { month: "long" })}{" "}
                {currentDate.getFullYear()}
              </h2>
              <div
                className={styles.dropdown}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <IoMdArrowDropdown />
                {isDropdownOpen && (
                  <ul className={styles.dropdownMenu}>
                    {getSelectableMonths().map((month) => (
                      <li
                        key={month.getMonth()}
                        onClick={() => handleMonthSelect(month)}
                      >
                        {month.toLocaleString("default", { month: "long" })}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <FaArrowRight onClick={goToNextMonth} />
            </span>
            <div className="flex gap-1 items-center ">
              <span>User: </span>{" "}
              {role === "admin" ? (
                <select className="ml-2 text-black h-6 border border-black rounded-md">
                  <option value="edit"> All</option>
                  <option value="delete">User 1</option>
                </select>
              ) : (
                <span>Name</span>
              )}
            </div>
            <div className="flex gap-1 items-center">
              <span>Project: </span>
              <select className="ml-2 text-black h-6 border border-black rounded-md">
                <option value="edit"> All</option>
                <option value="delete">Projects 1</option>
              </select>
            </div>
          </div>

          <div className={styles.productivity_main}>
            <span>Total day time: All</span>
            <span>Active: All</span>
            <span className={styles.productivity_div}>
              Productivity: 55% (
              <div className={styles.productivity_inner_div}>
                <FaKeyboard /> 18% <FaMouse /> 50%{" "}
              </div>
              )
            </span>{" "}
          </div>
          <span
            className={`${styles["month-div"]} cursor-pointer`}
            onClick={openModal}
          >
            <FaFilter />
            Filter off
            <IoMdArrowDropdown />
          </span>
        </div>

        <hr className={styles.hr} />
        <div className={`${styles.flexRow} mb-4`}>
          <label className={styles.options}>
            Select:
            <select className={styles.selectBox}>
              <option value="None">None</option>
            </select>
            <select className={styles.selectBox}>
              <option value="Monitor">Monitor</option>
            </select>
          </label>
          <button onClick={() => setShowMore(!showMore)}>
            <VscGraph /> Show statistics
          </button>
          <button onClick={() => setShowOfflineActivity(!showOfflineActivity)}>
            <FaRegLightbulb /> Show offline activity
          </button>
        </div>
        <hr />
        {showOfflineActivity ? (
          <OfflineActivityForm />
        ) : (
          <div className={styles["activity-section"]}>
            <div className={styles["table-container"]}>
              <table className={styles["activity-table"]}>
                <tbody>
                  {[...Array(3)].map((_, i) => (
                    <>
                      <tr key={i} className={styles.table_row}>
                        <td className={styles["activity-first-cell"]}>
                          <div className={styles.time_main1}>
                            <div className={styles.time_div1}>
                              <input
                                type="checkbox"
                                className={styles.checkboxMargin}
                              />
                              <span>4AM</span>
                            </div>
                            <div className={styles.time_div2}>+35m</div>
                          </div>
                          <div className={styles.time_main2}>
                            <div>
                              47% <FaKeyboard />{" "}
                            </div>
                            <div>
                              18% <FaMouse />
                            </div>
                          </div>
                          <div
                            className={styles.time_main3}
                            onClick={() => setShowMore(!showMore)}
                          >
                            {!showMore ? (
                              <>
                                more <IoMdArrowDropdown />
                              </>
                            ) : (
                              <>
                                less <IoMdArrowDropup />
                              </>
                            )}
                          </div>
                        </td>
                        {[...Array(10)].map((_, j) => (
                          <div className="flex flex-col gap-1">
                            <td key={j} className={styles["activity-cell"]}>
                              <div className={styles.time_main1}>
                                <div className={styles.time_div1}>
                                  <input
                                    type="checkbox"
                                    className={styles.checkboxMargin}
                                  />
                                  <span>4AM</span>
                                </div>
                                <div className={styles.time_div2}>+35m</div>
                              </div>
                              <div>
                                <div
                                  className={styles.activity_img}
                                  onClick={handleOpenModal}
                                >
                                  BugTrack agent was disabled
                                </div>

                                <PreviewModal
                                  show={showModal}
                                  onClose={handleCloseModal}
                                  imgSrc={img}
                                />
                              </div>
                            </td>
                            {showMore ? (
                              <td>
                                <div className={styles.time_main2}>
                                  <div>
                                    47% <FaKeyboard />{" "}
                                  </div>
                                  <div>
                                    18% <FaMouse />
                                  </div>
                                </div>
                              </td>
                            ) : (
                              <></>
                            )}
                          </div>
                        ))}
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Activity;
