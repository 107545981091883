import React from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import HomePage from "./components/home/HomePage";
import Login from "./components/authpages/login/Login";
import SignUp from "./components/authpages/signup/SignUp";
import ForgotPassword from "./components/forgotPassword/ForgotPassword";
import SubscriptionPage from "./components/subscription/SubscriptionPage";
import Dashboard from "./components/Dashboard/Dashboard"; 
import Summary from "./components/Dashboard/summary/Summary";
import Activity from "./components/Dashboard/activity/Activity";
import Users from "./components/Dashboard/users/Users";
import AnalyticsAndCharts from "./components/Dashboard/analyticsAndCharts/AnalyticsAndCharts";
import Reports from "./components/Dashboard/reports/Reports";
import CreditCardForm from "./components/creditCardForm/CreditCardForm";
import ResetPassword from "./components/authpages/resetPassword/ResetPassword";
import PersonalInformation from "./components/Dashboard/Settings/personalInformation/PersonalInformation";
import Preferences from "./components/Dashboard/Settings/preferences/Preferences";
import HourlyRate from "./components/Dashboard/Settings/hourlyRate/HourlyRate";
import Error from "./components/404error/Error";
import TermsOfService from "./components/terms/TermsOfService";
import PrivacyPolicy from "./components/privacyPolicy/PrivacyPolicy";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} exact />
        <Route path="/:domain/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/subscriptionplan" element={<SubscriptionPage />} />
        <Route path="/terms_of_services" element={<TermsOfService />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="/dashboard" element={<Dashboard />}>
          <Route index element={<Navigate to="/dashboard/summary" />} />
          <Route path="summary" element={<Summary />} />
          <Route path="activity" element={<Activity />} />
          <Route path="users" element={<Users />} />
          <Route path="analytics_charts" element={<AnalyticsAndCharts />} />
          <Route path="reports" element={<Reports />} />
          <Route path="setting/personalinformation" element={<PersonalInformation />} />
          <Route path="setting/preferences" element={<Preferences />} />
          <Route path="setting/hourlyrate" element={<HourlyRate />} />
        </Route>
        <Route path="/payment" element={<CreditCardForm />} />
        <Route path="*" element={<Error />} />

      </Routes>
    </BrowserRouter>
  );
};

export default App;
