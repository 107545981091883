import React, { useState, useEffect, useRef } from "react";
import styles from "./creditCardForm.module.css";
import cardImg from "../../assets/card.jpeg";
import chipImg from "../../assets/chip.png";
import visaImg from "../../assets/visa.png";
import amexImg from "../../assets/amex.png";
import mastercardImg from "../../assets/mastercard.png";
import discoverImg from "../../assets/discover.png";
import ParticlesBackground from "../home/ParticalsBackground";
import AnimationLottie from "../../lottie/animation-lottie";
import creditCardLottie from "../../lottie/cardAnimation.json";
import BackBtn from "../backBtn/BackBtn";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { fetchApi } from "../../utlis/axios";
import { PaymentElement } from "@stripe/react-stripe-js";

const CreditCardForm = () => {
  const [cardName, setCardName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardMonth, setCardMonth] = useState("");
  const [cardYear, setCardYear] = useState("");
  const [cardCvv, setCardCvv] = useState("");
  const [isCardFlipped, setIsCardFlipped] = useState(false);
  const [focusElementStyle, setFocusElementStyle] = useState(null);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const { theme } = useSelector((state) => state.theme);
  const { plan } = useSelector((state) => state.plan);
  const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY
  const cardNumberRef = useRef(null);
  const cardNameRef = useRef(null);
  const cardDateRef = useRef(null);
  const focusRef = useRef(null);

  const minCardYear = new Date().getFullYear();
  const amexCardMask = "#### ###### #####";
  const otherCardMask = "#### #### #### ####";

  useEffect(() => {
    cardNumberRef.current.focus();
  }, []);

  const getCardType = () => {
    const number = cardNumber;
    if (/^4/.test(number)) return "visa";
    if (/^(34|37)/.test(number)) return "amex";
    if (/^5[1-5]/.test(number)) return "mastercard";
    if (/^6011/.test(number)) return "discover";
    return "visa";
  };

  const generateCardNumberMask = () => {
    return getCardType() === "amex" ? amexCardMask : otherCardMask;
  };

  const flipCard = (status) => {
    setIsCardFlipped(status);
  };

  const focusInput = (e) => {
    setIsInputFocused(true);
    const target =
      e.target.dataset.ref === "cardNumber"
        ? cardNumberRef.current
        : cardNameRef.current;
    setFocusElementStyle({
      width: `${target.offsetWidth}px`,
      height: `${target.offsetHeight}px`,
      transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`,
    });
    if (e.target.id === "cardCvv") {
      flipCard(true);
    } else {
      flipCard(false);
    }
  };

  const blurInput = () => {
    setTimeout(() => {
      if (!isInputFocused) {
        setFocusElementStyle(null);
      }
    }, 300);
    setIsInputFocused(false);
  };

  const makePayment =async ()=>{

    const stripe = await loadStripe("pk_test_51Puv8L091cg7uhmeUEIdlTk89B8MA9GpAGrAGVyplAxnLH1phmbfrvcroCwKBLB2OG4aiBS0vPPUsLZWBUXi8Z8k00YAdcRMLT")
    const body={
        plan
    }

    const response = await fetchApi.get('payment-session/')


    const result = stripe.redirectToCheckout({
      sessionId:response.id
    })
    if(result.error){
      console.log(result.error);
      
    }
  }

  const paymentElementOptions = {
    layout: "tabs",
    defaultCollapsed: false,
  };

  return (
    <>
      <ParticlesBackground id="particaljs" />
      <BackBtn bgColor={"red"} />
      <div className={styles.wrapper}>
        <div className={styles.container}>
          {/* Lottie Animation */}
          <div className={styles.lottieWrapper}>
            <AnimationLottie animationPath={creditCardLottie} />
          </div>
          {/* <PaymentElement id="payment-element" options={paymentElementOptions} /> */}
          <div className={`${styles.cardForm} ${theme === 'dark' ? 'bg-white' : 'bg-gray-300'}`}>
            <div className={styles.cardList}>
              <div
                className={`${styles.cardItem} ${
                  isCardFlipped ? styles.active : ""
                }`}
              >
                {/* Front Side */}
                <div className={`${styles.cardItemSide} ${styles.front}`}>
                  <div
                    className={styles.cardItemFocus}
                    style={focusElementStyle}
                    ref={focusRef}
                  ></div>
                  <div className={styles.cardItemCover}>
                    <img
                      src={cardImg}
                      className={styles.cardItemBg}
                      alt="Card background"
                    />
                  </div>
                  <div className={styles.cardItemWrapper}>
                    <div className={styles.cardItemTop}>
                      <img
                        src={chipImg}
                        className={styles.cardItemChip}
                        alt="Card chip"
                      />
                      <div className={styles.cardItemType}>
                        <img
                          src={
                            getCardType() === "visa"
                              ? visaImg
                              : getCardType() === "amex"
                              ? amexImg
                              : getCardType() === "mastercard"
                              ? mastercardImg
                              : getCardType() === "discover"
                              ? discoverImg
                              : visaImg
                          }
                          className={styles.cardItemTypeImg}
                          alt="Card type"
                        />
                      </div>
                    </div>
                    {/* Card Number */}
                    <label
                      htmlFor="cardNumber"
                      className={styles.cardItemNumber}
                      ref={cardNumberRef}
                    >
                      {cardNumber || generateCardNumberMask()}
                    </label>
                    <div className="flex gap-1">
                      {/* Card Holder Name */}
                      <label
                        htmlFor="cardName"
                        className={styles.cardItemInfo}
                        ref={cardNameRef}
                      >
                        <div className={styles.cardItemHolder}>Card Holder</div>
                        <div className={styles.cardItemName}>
                          {cardName || "Full Name"}
                        </div>
                      </label>
                      {/* Card Expiry */}
                      <div className={styles.cardItemDate} ref={cardDateRef}>
                        <div className={styles.cardItemDateTitle}>Expires</div>
                        <div className={styles.cardItemDateItem}>
                          {cardMonth || "MM"} / {cardYear || "YY"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Back Side */}
                <div className={`${styles.cardItemSide} ${styles.back}`}>
                  <div className={styles.cardItemCover}>
                    <img
                      src={cardImg}
                      className={styles.cardItemBg}
                      alt="Card background"
                    />
                  </div>
                  <div className={styles.cardItemBand}></div>
                  <div className={styles.cardItemCvv}>
                    <div className={styles.cardItemCvvTitle}>CVV</div>
                    <div className={styles.cardItemCvvBand}>
                      {cardCvv || "***"}
                    </div>
                    <div className={`${styles.cardItemType} absolute mb-2`}>
                      <img
                        src={
                          getCardType() === "visa"
                            ? visaImg
                            : getCardType() === "amex"
                            ? amexImg
                            : getCardType() === "mastercard"
                            ? mastercardImg
                            : getCardType() === "discover"
                            ? discoverImg
                            : visaImg
                        }
                        className={styles.cardItemTypeImg}
                        alt="Card type"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Input Fields */}
            <div className={styles.cardFormInner}>
              <div className={styles.cardInput}>
                <label htmlFor="cardNumber" className={styles.cardInputLabel}>
                  Card Number
                </label>
                <input
                  type="text"
                  maxLength={16}
                  minLength={16}
                  id="cardNumber"
                  className={styles.cardInputInput}
                  value={cardNumber}
                  onFocus={focusInput}
                  onBlur={blurInput}
                  onChange={(e) => setCardNumber(e.target.value)}
                  data-ref="cardNumber"
                />
              </div>
              <div className={styles.cardInput}>
                <label htmlFor="cardName" className={styles.cardInputLabel}>
                  Card Name
                </label>
                <input
                  type="text"
                  id="cardName"
                  className={styles.cardInputInput}
                  value={cardName}
                  onFocus={focusInput}
                  onBlur={blurInput}
                  onChange={(e) => setCardName(e.target.value)}
                  data-ref="cardName"
                />
              </div>
              <div className={styles.cardFormRow}>
                <div className={styles.cardInputs}>
                  <label htmlFor="expiration" className={styles.cardInputLabel}>
                    Expiration Date
                  </label>
                  <select
                    id="expiration"
                    className={`${styles.cardInputInput} ${styles.select}`}
                    value={cardMonth}
                    onFocus={focusInput}
                    onBlur={blurInput}
                    onChange={(e) => setCardMonth(e.target.value)}
                  >
                    <option value="">Month</option>
                    {[...Array(12).keys()].map((n) => (
                      <option key={n + 1} value={n + 1}>
                        {n + 1 < 10 ? `0${n + 1}` : n + 1}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={styles.cardInputs}>
                  <label htmlFor="cardYear" className={styles.cardInputLabel}>
                    &nbsp;
                  </label>
                  <select
                    id="cardYear"
                    className={`${styles.cardInputInput} ${styles.select}`}
                    value={cardYear}
                    onFocus={focusInput}
                    onBlur={blurInput}
                    onChange={(e) => setCardYear(e.target.value)}
                  >
                    <option value="">Year</option>
                    {[...Array(12).keys()].map((n) => (
                      <option key={n} value={minCardYear + n}>
                        {minCardYear + n}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={styles.cardInputs}>
                  <label htmlFor="cardCvv" className={styles.cardInputLabel}>
                    CVV
                  </label>
                  <input
                    type="text"
                    maxLength={3}
                    minLength={3}
                    id="cardCvv"
                    className={styles.cardInputInput}
                    value={cardCvv}
                    onFocus={focusInput}
                    onBlur={blurInput}
                    onChange={(e) => setCardCvv(e.target.value)}
                    data-ref="cardCvv"
                  />
                </div>
              </div>
              <div className={styles.cardInput} >
               <Link to="/dashboard"> <button className="heroButton w-full mt-4">Submit</button></Link>
               {/* <button className="heroButton w-full mt-4" onClick={makePayment}>Submit</button> */}
              </div> 
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreditCardForm;
