import React, { useState } from "react";
import backgroundImage from "../../assets/loginBg.png";
import BackBtn from "../backBtn/BackBtn";
import { useSelector } from "react-redux";

const ForgotPassword = () => {
  const { theme } = useSelector((state) => state.theme);
  const [email, setEmail] = useState(true);
  return (
    <>
    <style>{`
        .login-box {
  background-color:${theme === "dark" ? "rgba(33, 33, 33, 0.9)" : "#dfdfdf"}  ;
  color:${theme === "dark" ? "#ffffff" : "#000000"};
}
  .input-field {
  background-color:${theme === "dark" ? "#444" : "#ffffff"}  ;
  color:${theme === "dark" ? "#ffffff" : "#000000"};
  }
  .forgot-link {
  color:${theme === "dark" ? "#ccc" : "#000000"};
  }
  .terms-text {
  color:${theme === "dark" ? "#ccc" : "#000000"};
  }
  .terms-link {
  color:${theme === "dark" ? "#ffffff" : "#ff2f2f"};
  }
    `}</style>
    <BackBtn bgColor={"red"} />
    <div
      className="login-container"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      {email ? (
        <div className="login-box">
          <h2 className="main-heading text-center">Forgot Password</h2>
          <div className="input-group">
            <label className={`input-label ${theme === "dark" ? "text-gray-400" : "text-black"}`}>Enter your email</label>
            <input
              type="email"
              placeholder="Enter your email"
              className="input-field"
              required
            />
          </div>
          <button className="heroButton w-full" onClick={()=>{setEmail(false)}}>Send me email</button>
          <p className="terms-text">
            A note about spam filters : If you don't get an email from us within
            a few minutes please be sure to check your spam filter. The email
            will be coming from do-not-reply@bugtrack.com.
          </p>
        </div>
      ) : (
        <div className="login-box">
          <h2 className="main-heading text-center">Check your mail box</h2>
          <p className="terms-text">
            A note about spam filters : If you don't get an email from us within
            a few minutes please be sure to check your spam filter. The email
            will be coming from do-not-reply@bugtrack.com.
          </p>
          <div className="forgot-password">
          <a href="/your-domain-name/login" className="forgot-link">Back to login</a>
        </div>
        </div>
      )}
    </div>
    </>
    
  );
};

export default ForgotPassword;
