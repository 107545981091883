import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    plan: 'basic',
  };

  const plansSlice = createSlice({
    name: 'plan',
    initialState,
    reducers: {
      togglePlan: (state, { payload }) => {
        state.plan = payload?.plan;
      }
    },
  });
  export const { togglePlan } = plansSlice.actions;

  export default plansSlice.reducer;
  