import { FaHome } from "react-icons/fa";
import { MdOutlineSummarize } from "react-icons/md";
import { IoBarChartSharp } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
import { LuActivitySquare } from "react-icons/lu";
import { TbReportSearch } from "react-icons/tb";

const routes = [
  {
    role:"admin",
    path: "/",
    name: "Home",
    icon: <FaHome />,
  },
  {
    role:"admin",
    path: "/dashboard/summary",
    name: "Summary",
    icon: <MdOutlineSummarize />,
  },
  {
    role:"admin",
    path: "/dashboard/activity",
    name: "Activity",
    icon: <LuActivitySquare />,
  },
  {
    role:"admin",
    path: "/dashboard/users",
    name: "Users",
    icon: <FaRegUser />,
  },
  {
    role:"admin",
    path: "/dashboard/analytics_charts",
    name: "Analytics & Charts",
    icon: <IoBarChartSharp />,
  },
  {
    role:"admin",
    path: "/dashboard/reports",
    name: "Reports",
    icon: <TbReportSearch />,
  }
];
export default routes;
