import React, { useState } from "react";
import Sidebar from "./Sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import DashboardNavbar from "./Navbar/DashboardNavbar";
import GoToTop from "../goToTop/GoToTop";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const { theme } = useSelector((state) => state.theme);
  const [isopen, setIsopen] = useState(false);
  const toggle = () => setIsopen(!isopen);
  const toggleSidebar = () => {
    setIsopen(false);
  };

  return (
    <div className="relative min-h-screen overflow-hidden">
      <GoToTop/>
      <div className="flex min-h-screen text-white">
        <div className="fixed z-50">
          <Sidebar isopen={isopen} toggle={toggle} />
        </div>
        <div
          className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 ${
            isopen ? "z-40" : "hidden"
          }`}
          onClick={toggleSidebar}
        />
        <div className={`flex-grow min-h-screen  overflow-hidden ${
            theme === 'dark' ? "bg-dashboardDarkMode" : "bg-white"
          }`}>
          <DashboardNavbar />
          <div className="ml-16 py-5 h-[calc(100vh-60px)] overflow-auto">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
