import React from 'react';
import styles from './resetPassword.module.css';
import backgroundImage from '../../../assets/loginBg.png'; 
import BackBtn from '../../backBtn/BackBtn';

const ResetPassword = () => {
  return (
    <>
    <BackBtn bgColor={'red'}/>
    <div className={styles['reset-container']} style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className={styles['reset-box']}>
        <h2 className="main-heading text-center text-white">Reset Password</h2>
        <div className={styles['input-group']}>
          <label className={styles['input-label']}>Enter your email</label>
          <input type="email" placeholder="Enter your email" className={styles['input-field']} />
        </div>
        <div className={styles['input-group']}>
          <label className={styles['input-label']}>New Password</label>
          <input type="password" placeholder="Enter your new password" className={styles['input-field']} />
        </div>
        <div className={styles['input-group']}>
          <label className={styles['input-label']}>Confirm Password</label>
          <input type="password" placeholder="Confirm your new password" className={styles['input-field']} />
        </div>
        <button className={styles['reset-button']}>Reset Password</button>
        <div className={styles['back-to-login']}>
          <a href="/your-domain-name/login" className={styles['back-link']}>Back to Login</a>
        </div>
      </div>
    </div>
    </>
    
  );
};

export default ResetPassword;
