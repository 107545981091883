import React, { useState } from "react";
import { FaRegEye } from "react-icons/fa";
import { IoSearchSharp } from "react-icons/io5";
import styles from "./createReportModal.module.css";
import { useSelector } from "react-redux";

const CreateReportModal = ({ onClose }) => {
  const { theme } = useSelector((state) => state.theme);
  const [title, setTitle] = useState("");
  const [timeZone, setTimeZone] = useState("My TZ (GMT0)");
  const [reportType, setReportType] = useState("User worktime");
  const [showDetailed, setShowDetailed] = useState(false);
  const [projects, setProjects] = useState(["Select All"]);
  const [users, setUsers] = useState(["Select All"]);
  const [period, setPeriod] = useState("Day");
  const [date, setDate] = useState("");
  const [format, setFormat] = useState("XLS");
  const [sendToEmail, setSendToEmail] = useState(true);
  const [email, setEmail] = useState("");
  const [sendIfZero, setSendIfZero] = useState(false);

  const handleCreate = () => {
    
    console.log({
      title,
      timeZone,
      reportType,
      showDetailed,
      projects,
      users,
      period,
      date,
      format,
      sendToEmail,
      email,
      sendIfZero,
    });
    onClose();
  };

  return (
    <>
      <style>
        {`
          .reportInput {
  width: 100%;
  padding: 10px;
  border: ${theme === "dark" ? "1px solid #ffffff" : "1px solid #000000"};
  border-radius: 5px;
  margin-top: 5px;
  box-sizing: border-box; 
  overflow-y: auto;
  background-color: transparent;
}.reportInput option{
  color: black;
}

      `}
      </style>
      <div className={styles.modalOverlay}>
        <div className={`${styles.modalContent} ${
            theme === "dark"
              ? "bg-[#212529] text-white"
              : "bg-[#c3c8cd] text-black"
          }`}>
          <div className={styles.header}>
            <h2>Create Report</h2>
            <button className={styles.closeButton} onClick={onClose}>
              ✕
            </button>
          </div>
          <div className="text-left">
            <div className={styles.formGroup}>
              <label>Title*</label>
              <input
                className="reportInput"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>
            <div className={styles.formGroupRow}>
              <div className={styles.formGroup}>
                <label>Time zone</label>
                <select
                  className="reportInput"
                  value={timeZone}
                  onChange={(e) => setTimeZone(e.target.value)}
                >
                  <option>My TZ (GMT0)</option>
                  <option>Domain TZ (GMT0)</option>
                </select>
              </div>
              <div className={styles.formGroup}>
                <label>Report type</label>
                <select
                  className="reportInput"
                  value={reportType}
                  onChange={(e) => setReportType(e.target.value)}
                >
                  <option>User worktime</option>
                  <option>Start and end of work</option>
                  <option>User worktable</option>
                </select>
              </div>
            </div>
            {reportType === "User worktime" ? (
              <div className={styles.formGroupCheckbox}>
                <input
                  className="reportInput"
                  type="checkbox"
                  checked={showDetailed}
                  onChange={(e) => setShowDetailed(e.target.checked)}
                />
                <label>Show detailed report</label>
              </div>
            ) : (
              <></>
            )}

            <div className={styles.formGroupRow}>
              <div className={styles.formGroup}>
                <div className={styles.formInnerGroup}>
                  <label>Projects*</label>
                  <div className={styles.formGroupCheckbox}>
                    <input
                      className="reportInput"
                      type="checkbox"
                      checked={showDetailed}
                      // onChange={(e) => setShowDetailed(e.target.checked)}
                    />
                    <label>Show archived</label>
                  </div>
                </div>
                <div>
                  <div className={styles.formGroupSearch}>
                    <input
                      type="text"
                      placeholder="Search"
                      className="reportInput"
                    />
                    <IoSearchSharp className={styles.checkboxIcon} />
                  </div>
                  <select
                    className="reportInput"
                    multiple
                    value={projects}
                    onChange={(e) =>
                      setProjects(
                        [...e.target.selectedOptions].map((o) => o.value)
                      )
                    }
                  >
                    <option>Select All</option>
                    <option>Not selected</option>
                  </select>
                </div>
              </div>
              <div className={styles.formGroup}>
                <div className={styles.formInnerGroup}>
                  <label>Users*</label>
                  <div className={styles.formGroupCheckbox}>
                    <input
                      className="reportInput"
                      type="checkbox"
                      checked={showDetailed}
                      // onChange={(e) => setShowDetailed(e.target.checked)}
                    />
                    <label>Show archived</label>
                  </div>
                </div>
                <div>
                  <div className={styles.formGroupSearch}>
                    <input
                      type="text"
                      placeholder="Search"
                      className="reportInput"
                    />
                    <IoSearchSharp className={styles.checkboxIcon} />
                  </div>
                  <select
                    className="reportInput"
                    multiple
                    value={users}
                    onChange={(e) =>
                      setUsers(
                        [...e.target.selectedOptions].map((o) => o.value)
                      )
                    }
                  >
                    <option>Select All</option>
                    <option>Hamza Tariq</option>
                  </select>
                </div>
              </div>
            </div>
            <div className={styles.formGroupRow}>
              <div className={styles.formGroup}>
                <label>Period</label>
                <select
                  className="reportInput"
                  value={period}
                  onChange={(e) => setPeriod(e.target.value)}
                >
                  <option>Day</option>
                  <option>Week</option>
                  <option>Month</option>
                  <option>Custom</option>
                </select>
              </div>
              <div className={styles.formGroup}>
                <label>Date</label>
                <input
                  className="reportInput"
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>
              <div className={styles.formGroup}>
                <label>Format</label>
                <select
                  className="reportInput"
                  value={format}
                  onChange={(e) => setFormat(e.target.value)}
                >
                  <option>XLS</option>
                  <option>CSV</option>
                </select>
              </div>
            </div>
            <div className={styles.formGroupCheckbox}>
              <input
                className="reportInput"
                type="checkbox"
                checked={sendToEmail}
                onChange={(e) => setSendToEmail(e.target.checked)}
              />
              <label>Send to email</label>
            </div>
            {sendToEmail && (
              <>
                <div className={styles.formGroup}>
                  <label>E-Mail</label>
                  <input
                    className="reportInput"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className={styles.formGroupCheckbox}>
                  <input
                    type="checkbox"
                    checked={sendIfZero}
                    onChange={(e) => setSendIfZero(e.target.checked)}
                  />
                  <label>Send if zero activity</label>
                </div>
              </>
            )}
          </div>
          <div className={styles.footer}>
            <div className={styles.footer_innerDiv}>
              <button className="heroButton" onClick={handleCreate}>
                Create
              </button>
              <button className="heroButton" onClick={onClose}>
                Cancel
              </button>
            </div>
            <button
              className="heroButton"
              style={{ display: "flex", gap: "5px", alignItems: "center" }}
            >
              <FaRegEye /> Preview
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateReportModal;
