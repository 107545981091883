import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { toggleAuth } from "../../../Redux/Slices/auth-slice";
import { fetchApi } from "../../../utlis/axios";
import backgroundImage from "../../../assets/loginBg.png";
import { toast } from "react-toastify";
import BackBtn from "../../backBtn/BackBtn";

const Login = () => {
  const { theme } = useSelector((state) => state.theme);
  const { domain } = useParams();
  console.log(domain);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { isLogin, userInfo } = useSelector((state) => state?.auth || {});
  const [formValues, setFormValues] = useState({ email: "", password: "" });

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!formValues?.email || !formValues?.password) {
      toast.error("All fields are required!");
      return;
    }
    setLoading(true);
    try {
      const result = await fetchApi.post("login/", formValues);
      console.log(result);

      if (result?.data?.status === 200) {
        dispatch(toggleAuth({ isLogin: true, userInfo: result?.data }));
        setLoading(false);
      } else {
        setError("Given credentials are wrong");
        setLoading(false);
      }
    } catch (error) {
      setError(
        "An error occurred while trying to log in. Please try again later."
      );
      console.error(error);
      toast.error(error?.response?.data?.detail || "Login failed");
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  return (
    <>
      <style>{`
        .login-box {
  background-color:${theme === "dark" ? "rgba(33, 33, 33, 0.9)" : "#dfdfdf"}  ;
  color:${theme === "dark" ? "#ffffff" : "#000000"};
}
  .input-field {
  background-color:${theme === "dark" ? "#444" : "#ffffff"}  ;
  color:${theme === "dark" ? "#ffffff" : "#000000"};
  }
  .forgot-link {
  color:${theme === "dark" ? "#ccc" : "#000000"};
  }
  .terms-text {
  color:${theme === "dark" ? "#ccc" : "#000000"};
  }
  .terms-link {
  color:${theme === "dark" ? "#ffffff" : "#ff2f2f"};
  }
    `}</style>
      <div
        className="login-container"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <BackBtn bgColor={"red"} />
        <div className="login-box">
          <h2 className="main-heading text-center">Login</h2>
          {error && <p className="error-text">{error}</p>}
          <form onSubmit={handleLogin}>
            <div className="input-group">
              <label className={`input-label ${theme === "dark" ? "text-gray-400" : "text-black"} `}>Enter your email</label>
              <input
                type="email"
                name="email"
                value={formValues.email}
                onChange={handleChange}
                placeholder="Enter your email"
                className="input-field"
              />
            </div>
            <div className="input-group">
              <label className={`input-label ${theme === "dark" ? "text-gray-400" : "text-black"}`}>Enter your password</label>
              <input
                type="password"
                name="password"
                value={formValues.password}
                onChange={handleChange}
                placeholder="Enter your password"
                className="input-field"
              />
            </div>
            <div className="forgot-password">
              <a href="/forgotpassword" className="forgot-link">
                Forgot your password?
              </a>
            </div>
            <button
              className="heroButton w-full"
              type="submit"
              disabled={loading}
            >
              {loading ? "Logging in..." : "Login"}
            </button>
          </form>
          <div className="forgot-password">
            <a href="/subscriptionplan" className="forgot-link">
              Do not have any account?
            </a>
          </div>
          <p className="terms-text">
            By continuing, you accept our{" "}
            <NavLink to="/terms_of_services" className="terms-link">
              Terms of Service
            </NavLink>{" "}
            and{" "}
            <NavLink to="/privacy_policy" className="terms-link">
              Privacy Policy
            </NavLink>
            .
          </p>
        </div>
      </div>
    </>
  );
};

export default Login;
