import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import GlowCard from "./glow-card";
import { togglePlan } from "../../../Redux/Slices/plans-slice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchApi } from "../../../utlis/axios";
import { toast } from "react-toastify";

const PricingSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getPlans = async () => {
    setIsLoading(true);
    try {
      const result = await fetchApi.get("plans/");
      if (result.status === 200) {
        setPlans(result.data);
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPlans();
  }, []);

  const handlePlans = (plan) => {
    dispatch(togglePlan({ plan: plan }));
    navigate("/signup");
  };
  return (
    <section className={styles.pricingSection}>
      <div className="container">
        <h2 className={`main-heading text-center py-8 `}>Subscription Plans</h2>
        {isLoading ? (
          <>Loading...</>
        ) : (
          <div className={styles.pricingPlans}>
            {plans.map((plan, index) => (
              <GlowCard key={plan.id} identifier={index}>
                <div className={styles.plan}>
                  <h3 className={styles.planTitle}>{plan?.name}</h3>
                  <p className={styles.planDescription}>{plan?.description}</p>
                  <p className={styles.planPrice}>
                    {(plan?.amount / 100).toFixed(2)} {plan?.currency}
                    <sub className={styles.planSubprice}>/month</sub>
                  </p>
                  {/* <ul className={styles.planFeatures}>
                  <li>Feature 1</li>
                  <li>Feature 2</li>
                  <li>Feature 3</li>
                </ul> */}
                  <button
                    className="heroButton"
                    onClick={() => handlePlans(plan?.id)}
                  >
                    Sign Up
                  </button>
                </div>
              </GlowCard>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default PricingSection;
