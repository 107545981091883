import React, { useEffect, useState } from "react";
import { MdElectricBolt, MdMenu, MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {toggleTheme} from '../../Redux/Slices/theme-slice'
 
const Navbar = () => {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const dispatch = useDispatch();
  const { theme } = useSelector((state) => state.theme);

  const handleToggleTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    dispatch(toggleTheme({ theme: newTheme }));
  }; 
  

  const toggleNavVisibility = () => {
    setIsNavVisible(!isNavVisible);
  };

  return (
    <header className={`sticky-navbar ${theme === 'dark' ? 'dark_mode' : 'light_mode'}`}>
      <div className="header-top">
        <div className="logo">
          <MdElectricBolt />
          <span>
            <span className="text-[#FF0000] ">Bug</span>Track
          </span>
        </div>
        <button className="nav-toggle-btn" onClick={toggleNavVisibility}>
          {isNavVisible ? <MdClose /> : <MdMenu />}
        </button>
      </div>
      <nav className={`${isNavVisible ? "visible" : ""} ${isNavVisible ? (theme === 'dark' ? 'navdark' : 'navlight') : ''}`}>
        <NavLink to="/dashboard">Dashboard</NavLink>
        <a href="#features">Features</a>
        <a href="#contactUs">Contact Us</a>
        <button
          className={`p-2 text-sm rounded-full transition duration-500 text-white ${
            theme !== 'dark' ? "bg-black " : "bg-custom-gradient "
          }`}
          onClick={handleToggleTheme}
        >
          {theme !== 'dark' ? "🌙 Dark Mode" : "☀️ Light Mode"}
        </button>
        <NavLink to="/your-domain-name/login" className="heroButton">
          Sign In
        </NavLink>
        <NavLink to="/subscriptionplan" className="heroButton">
          Get Started
        </NavLink>
      </nav>
    </header>
  );
};

export default Navbar;
