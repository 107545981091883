import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";
import { FaFilter } from "react-icons/fa";
import styles from "./summary.module.css";
import FilterModal from "../filterModal/FilterModal";
import user from "../../../store";
import Tooltip from "../../tooltip/Tooltip";
import { useSelector } from "react-redux";

const Summary = () => {
  const { theme } = useSelector((state) => state.theme);
  const role = "admin1";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [days, setDays] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const joiningDate = new Date(user.joiningDate);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    generateCalendar(currentDate);
  }, [currentDate]);

  const generateCalendar = (date) => {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const lastDayOfPrevMonth = new Date(
      date.getFullYear(),
      date.getMonth(),
      0
    ).getDate();

    const daysInMonth = [];
    for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
      daysInMonth.push({
        day: i,
        currentMonth: true,
      });
    }

    for (let i = firstDayOfMonth.getDay() - 1; i >= 0; i--) {
      daysInMonth.unshift({
        day: lastDayOfPrevMonth - i,
        currentMonth: false,
      });
    }

    const totalDays = daysInMonth.length;
    for (let i = 1; totalDays + i <= 35; i++) {
      daysInMonth.push({
        day: i,
        currentMonth: false,
      });
    }

    setDays(daysInMonth);
  };

  const goToPrevMonth = () => {
    const prevMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      1
    );
    if (
      prevMonth.getFullYear() > joiningDate.getFullYear() ||
      (prevMonth.getFullYear() === joiningDate.getFullYear() &&
        prevMonth.getMonth() >= joiningDate.getMonth())
    ) {
      setCurrentDate(prevMonth);
    }
  };

  const goToNextMonth = () => {
    const nextMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );
    const today = new Date();
    if (nextMonth <= today) {
      setCurrentDate(nextMonth);
    }
  };

  const renderCalendarRows = () => {
    const rows = [];
    for (let i = 0; i < days.length; i += 7) {
      rows.push(days.slice(i, i + 7));
    }
    return rows;
  };

  const getSelectableMonths = () => {
    const selectableMonths = [];
    const currentYear = currentDate.getFullYear();
    const currentMonth = new Date().getMonth();

    for (let month = joiningDate.getMonth(); month <= currentMonth; month++) {
      selectableMonths.push(new Date(currentYear, month, 1));
    }

    return selectableMonths;
  };

  const handleMonthSelect = (month) => {
    setCurrentDate(month);
    setIsDropdownOpen(false);
  };

  return (
    <div className={styles.outlet} >
      {isModalOpen && <FilterModal onClose={closeModal} />}
      <div className={`${styles["calendar-section"]} ${theme === 'dark' ? 'bg-[#2c2c2e] text-white' : 'bg-lightMode text-black'}`}>
        <div className={styles["calendar-header"]}>
          <span className={styles["month-div"]}>
            <FaArrowLeft onClick={goToPrevMonth} />
            <h2>
              {currentDate.toLocaleString("default", { month: "long" })}{" "}
              {currentDate.getFullYear()}
            </h2>
            <div
              className={styles.dropdown}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              onMouseLeave={() => setIsDropdownOpen(false)}
            >
              <IoMdArrowDropdown />
              {isDropdownOpen && (
                <ul className={styles.dropdownMenu}>
                  {getSelectableMonths().map((month) => (
                    <li
                      key={month.getMonth()}
                      onClick={() => handleMonthSelect(month)}
                    >
                      {month.toLocaleString("default", { month: "long" })}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <FaArrowRight onClick={goToNextMonth} />
          </span>
          <div className="flex space-x-4">
            <Tooltip content='Up Coming'>
            <div className="flex gap-1 items-center">
              <span>Projects:</span>
              {role === "admin" ? (
                <select className="ml-2 text-black h-6">
                  <option value="edit"> All</option>
                  <option value="delete">Projects 1</option>
                </select>
              ) : (
                <span>All</span>
              )}
            </div>
            </Tooltip>
            <Tooltip content='Up Coming'>
            <div className="flex gap-1 items-center">
              <span>Users: </span>
              {role === "admin" ? (
                <select className="ml-2 text-black h-6">
                  <option value="edit">All</option>
                  <option value="delete">Users1</option>
                  <option value="delete">Users2</option>
                </select>
              ) : (
                <span>All</span>
              )}
            </div>
            </Tooltip>
          </div>
          <span
            className={`${styles["month-div"]} cursor-pointer`}
            onClick={openModal}
          >
            <FaFilter />
            Filter off
          </span>
        </div>
        <div className={styles["calendar-inner-section"]}>
          <div className={styles["calendar-section"]}>
            <table className={`${styles["calendar-table"]} ${theme === 'dark' ? 'bg-[#1c1c1e]' : 'bg-[#b9b9ba]'}`}>
              <thead>
                <tr>
                  {[
                    "Sun",
                    "Mon",
                    "Tue",
                    "Wed",
                    "Thu",
                    "Fri",
                    "Sat",
                    "Week",
                  ].map((day) => (
                    <th key={day} className={styles["day-header"]}>
                      {day}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {renderCalendarRows().map((week, index) => (
                  <tr key={index}>
                    {week.map((day, i) => (
                      <td
                        key={i}
                        className={`${styles["calendar-cell"]} ${
                          day.currentMonth
                            ? theme === 'dark' ? 'bg-[#2c2c2e]' : 'bg-[#dadadb]'
                            : theme === 'dark' ? 'bg-[#3a3a3c]' : 'bg-[#b4b4b8]'
                        }`}
                      >
                        <div className={styles["calendar-inner-cell"]}>
                          <span>{day.day}</span>
                          <span className="text-right">8:00</span>
                        </div>
                      </td>
                    ))}
                    <td
                      className={`${styles["calendar-cell"]} ${styles.result}`}
                    >
                      44:00
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
