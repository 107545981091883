import React, { useState } from "react";
import styles from "./report.module.css";
import { FiDownload, FiSend, FiEdit, FiTrash } from "react-icons/fi";
import CreateReportModal from "./createReportModal/CreateReportModal";
import DetailReport from "./detailReport/DetailReport";
import Tooltip from "../../tooltip/Tooltip";

const EmptyState = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  const reports = [
    {
      title: "BugTrack Report",
      type: "Saved",
      actions: ["download", "send", "edit", "delete"],
    },
  ];

  // const report = [{ a: "a" }, { b: "b" }];
  const report = [];

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeDetailModel = () => {
    setShowDetail(false);
  };
  return (
    <div className={styles.main_container}>
      <div className={styles.actions}>
      <Tooltip content="Up Coming">
        {/* <button className="heroButton" onClick={openModal}> */}
        <button className="heroButton">
          Create
        </button>
        </Tooltip>
        {isModalOpen && <CreateReportModal onClose={closeModal} />}
        <Tooltip content="Up Coming">
          <button className="heroButton">Actions</button>
        </Tooltip>
      </div>
      {report.length === 0 ? (
        <div className={styles.container}>
          <div className={styles.content}>
            <p className={styles.message}>
              You have no reports yet. Try to create a new one.
            </p>
          </div>
        </div>
      ) : showDetail ? (
        <DetailReport closeDetailModel={closeDetailModel} />
      ) : (
        <div className={styles.tableContainer}>
          <table className={styles.reportTable}>
            <thead>
              <tr>
                <th>Title</th>
                <th>Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {reports.map((report, index) => (
                <tr key={index}>
                  <td>
                    <div
                      className={styles.titleCell}
                      onClick={() => setShowDetail(true)}
                    >
                      {" "}
                      <span className={styles.arrow}>{">"}</span>
                      <span
                        className={styles.reportLink}
                        onClick={() => setShowDetail(true)}
                      >
                        {report.title}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className={styles.typeCell}>
                      <span className={styles.statusDot}></span>
                      {report.type}
                    </div>
                  </td>
                  <td className={styles.actionsCell}>
                    <FiDownload
                      className={styles.actionIcon}
                      title="Download"
                    />
                    <FiSend className={styles.actionIcon} title="Send" />
                    <FiEdit className={styles.actionIcon} title="Edit" />
                    <FiTrash className={styles.actionIcon} title="Delete" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default EmptyState;
