import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { FaBars } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import routes from "./Data";
import profile from "../../../assets/face1.jpg";
import { useSelector } from "react-redux";

const Sidebar = ({ isopen, toggle }) => {
  const { theme } = useSelector((state) => state.theme);

  const user = {
    role: "admin",
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      width: "auto",
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <style>{`
       .sidebar_section .active {
        border-right:${
          theme === "dark" ? "4px solid white" : "4px solid black"
        } ;
        background: #d90a2c;
        color: white;
        }
      `}</style>
      <div className="flex whitespace-nowrap h-screen">
        <motion.div
          animate={{
            width: isopen ? "250px" : "60px",
            transition: {
              duration: 0.5,
              type: "spring",
              damping: 11,
            },
          }}
          className={` ${
            theme === "dark"
              ? "bg-darkMode text-white"
              : "bg-lightMode text-black"
          }  min-h-full shadow-lg overflow-y-auto overflow-x-hidden`}
        >
          <div className="flex items-center justify-between px-4 py-4 relative">
            <AnimatePresence>
              {isopen && (
                <motion.h1
                  className="text-2xl"
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                >
                  <NavLink to="/">
                    <span className="font-bold text-[#d90a2c]">Bug</span>Track
                  </NavLink>
                </motion.h1>
              )}
            </AnimatePresence>

            <div className="text-2xl cursor-pointer hover:text-[#d90a2c] transition-colors duration-300">
              <FaBars onClick={toggle} />
            </div>
          </div>

          <div className="flex items-center px-4 py-6 space-x-4 border-b border-gray-700">
            <div className="relative">
              <img
                className={`rounded-full transition-all duration-500 ${
                  isopen ? "w-12 h-12" : "w-8 h-8"
                }`}
                src={profile}
                alt="profile"
              />
            </div>
            <AnimatePresence>
              {isopen && (
                <motion.div
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                >
                  <h5 className="text-lg font-medium mb-0">Hamza Tariq</h5>
                  <span className="text-sm text-gray-400">Time Reporter</span>
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          <section className="mt-8 flex flex-col gap-4 px-2 sidebar_section">
            {routes.map((route, index) => {
              if (route?.role === user?.role) {
                return (
                  <NavLink
                    className="flex items-center gap-4 p-3 rounded-lg transition-all duration-300 hover:bg-[#d90a2c] hover:text-white"
                    to={route?.path}
                    key={index}
                    style={{
                      borderLeft: isopen ? "4px solid transparent" : "none",
                    }}
                    activestyle={{
                      borderLeft: isopen ? "4px solid #d90a2c" : "none",
                    }}
                  >
                    <div className="text-xl">{route?.icon}</div>
                    <AnimatePresence>
                      {isopen && (
                        <motion.div
                          variants={showAnimation}
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          className="text-lg font-medium"
                        >
                          {route?.name}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </NavLink>
                );
              }
            })}
          </section>
        </motion.div>
      </div>
    </>
  );
};

export default Sidebar;
