import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import styles from './analyticsAndCharts.module.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
} from 'chart.js';
import { useSelector } from 'react-redux';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler
);

const AnalyticsAndCharts = () => {
  const {theme} = useSelector((state)=>state.theme)
  const weeklyWorkData = {
    labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    datasets: [
      {
        label: 'Hours Worked',
        data: [8, 7.5, 8, 6, 5, 4, 7],
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const productivityData = {
    labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    datasets: [
      {
        label: 'Productivity (%)',
        data: [80, 85, 75, 90, 60, 70, 95],
        fill: true,
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
        pointBackgroundColor: 'rgba(153, 102, 255, 1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(153, 102, 255, 1)',
      },
    ],
  };
 
  return (
    <div className={`${styles.outlet} ${theme === 'dark' ? 'text-white' : 'text-black'}`}>
    <div className={styles.container}>
      <div className={styles.chartWrapper}>
        <div className={styles.chartTitle}>Weekly Hours Worked</div>
        <Bar data={weeklyWorkData} />
      </div>

      <div className={styles.chartWrapper}>
        <div className={styles.chartTitle}>Weekly Productivity</div>
        <Line data={productivityData} />
      </div>
    </div>
    </div>
  );
};

export default AnalyticsAndCharts;
