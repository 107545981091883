// PreviewModal.jsx
import React from 'react';
import styles from './previewModal.module.css';
import { IoMdCloseCircleOutline } from "react-icons/io";

const PreviewModal = ({ show, onClose, imgSrc }) => {
  if (!show) return null;

  return (
    <div className={styles.modalOverlay}>
      
        <img src={imgSrc} alt="Preview" className={styles.modalImage} />
        <button className={styles.closeButton} onClick={onClose}>
          <IoMdCloseCircleOutline/>
        </button>
     
    </div>
  );
};

export default PreviewModal;
