import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleAuth } from "../../../Redux/Slices/auth-slice";
import { fetchApi } from "../../../utlis/axios";
import { toast } from "react-toastify";
import backgroundImage from "../../../assets/signup.png";
import BackBtn from "../../backBtn/BackBtn";
import { togglePlan } from "../../../Redux/Slices/plans-slice";

const SignUp = () => {
  const { theme } = useSelector((state) => state.theme);
  const { plan } = useSelector((state) => state.plan);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [formValues, setFormValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  });

  const handlePlanChange = (plan) => {    
    dispatch(togglePlan({ plan: plan }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    const { first_name, last_name, email, password, domain } = formValues;

    // Basic validation
    if (!first_name || !last_name || !email || !password || !domain) {
      toast.error("All fields are required!");
      return;
    }

    setLoading(true);
    try {
      const result = await fetchApi.post("register/", formValues, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (result?.data) {
        dispatch(toggleAuth({ isLogin: true, userInfo: result?.data }));
        toast.success("Account created successfully!");
        navigate("/dashboard");
      } else {
        setError("An error occurred during registration.");
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.detail || "Registration failed";
      setError(errorMessage);
      toast.error(errorMessage);
      console.log(error);

      // console.error('Error response:', error.response.data); // Detailed error log
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
          <style>{`
        .login-box {
  background-color:${theme === "dark" ? "rgba(33, 33, 33, 0.9)" : "#dfdfdf"}  ;
  color:${theme === "dark" ? "#ffffff" : "#000000"};
}
  .input-field {
  background-color:${theme === "dark" ? "#444" : "#ffffff"}  ;
  color:${theme === "dark" ? "#ffffff" : "#000000"};
  }
  .forgot-link {
  color:${theme === "dark" ? "#ccc" : "#000000"};
  }
  .terms-text {
  color:${theme === "dark" ? "#ccc" : "#000000"};
  }
  .terms-link {
  color:${theme === "dark" ? "#ffffff" : "#ff2f2f"};
  }
    `}</style>
    <div
      className="login-container"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <BackBtn bgColor={"red"} />
      <div className="login-box">
        <h2 className="main-heading text-center">Create an account</h2>
        {error && <p className="error-text">{error}</p>}{" "}
        {/* <form onSubmit={handleSignUp}> */}
        <form>
        <div className="input-group">
            <label className="input-label">Your selected plan</label>
            <select
              value={plan}
              onChange={(event) => handlePlanChange(event.target.value)}
              className="input-field"
            >
              <option value="" defaultChecked>
                Select Plan
              </option>
              <option value="basic">Basic</option>
              <option value="team">Team</option>
              <option value="max">Max</option>
            </select>
          </div>
          <div className="input-group">
            <label className="input-label">First Name</label>
            <input
              type="text"
              name="first_name"
              value={formValues.first_name}
              onChange={handleChange}
              placeholder="Enter your first name"
              className="input-field"
            />
          </div>
          <div className="input-group">
            <label className="input-label">Last Name</label>
            <input
              type="text"
              name="last_name"
              value={formValues.last_name}
              onChange={handleChange}
              placeholder="Enter your last name"
              className="input-field"
            />
          </div>
          <div className="input-group">
            <label className="input-label">Enter your email</label>
            <input
              type="email"
              name="email"
              value={formValues.email}
              onChange={handleChange}
              placeholder="Enter your email"
              className="input-field"
            />
          </div>
          <div className="input-group">
            <label className="input-label">Enter your password</label>
            <input
              type="password"
              name="password"
              value={formValues.password}
              onChange={handleChange}
              placeholder="Enter your password"
              className="input-field"
            />
          </div>
          <div className="input-group">
            <label className="input-label">Choose a domain</label>
            <div className="domain-main">
              <div className={`http ${theme === "dark" ? "text-white" : "text-black"}`}>http://</div>
              <input
                type="text"
                name="domain"
                value={formValues.domain}
                onChange={handleChange}
                className="input-field domain-input"
              />
              <div className="domain">.bugtrack.com</div>
            </div>
          </div>
          {/* <button className="heroButton" type="submit" disabled={loading}> */}
          <Link to="/payment"> <button className="heroButton w-full" disabled={loading}>
            {loading ? "Loading..." : "Next"}
          </button></Link>
        </form>
        <p className="terms-text">
          By continuing, you accept our{" "}
          <Link to="/terms_of_services" className="terms-link">
            Terms of Service
          </Link>{" "}
          and{" "}
          <Link to="/privacy_policy" className="terms-link">
            Privacy Policy
          </Link>
        </p>
      </div>
    </div>
    </>
  );
};

export default SignUp;
