import React, { useState } from 'react';
import styles from './personalInformation.module.css';
import { useSelector } from 'react-redux';

const PersonalInformation = () => {
  const{theme} = useSelector((state)=>state.theme)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    role: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validate = () => {
    let formErrors = {};
    if (!formData.name.trim()) formErrors.name = 'Name is required';
    if (!formData.email.trim()) formErrors.email = 'Email is required';
    if (!formData.role.trim()) formErrors.role = 'Role is required';
    if (!formData.currentPassword) formErrors.currentPassword = 'Current password is required';
    if (!formData.newPassword) formErrors.newPassword = 'New password is required';
    if (formData.newPassword !== formData.confirmPassword)
      formErrors.confirmPassword = 'Passwords do not match';
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      console.log('Form submitted:', formData);
    }
  };

  return (
    <>
    <style>{`
      .input {
  background-color:${theme === "dark" ? "#2a3038" : "#a8a8a8"}  ;
  color:${theme === "dark" ? "#ffffff" : "#000000"};
  width: 100%;
    border: 1px solid #ccc;
    height: calc(2.25rem + 2px);
    font-weight: 400;
    font-size: .875rem;
    padding: .625rem .6875rem;
    border-radius: 10px;
  }
    `}</style>
    <form onSubmit={handleSubmit} className={`${styles.container} ${theme === 'dark' ? ' text-white' : 'text-black'}`}>
      <h2 className={`${styles.label} text-center`}>Personal Information</h2>
      <div className={styles.formGroup}>
        <label className={styles.label}>
          Name:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className='input'
          />
          {errors.name && <p className={styles.error}>{errors.name}</p>}
        </label>
      </div>

      <div className={styles.formGroup}>
        <label className={styles.label}>
          Email:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className='input'
          />
          {errors.email && <p className={styles.error}>{errors.email}</p>}
        </label>
      </div>

      <div className={styles.formGroup}>
        <label className={styles.label}>
          Role:
          <input
            type="text"
            name="role"
            value={formData.role}
            onChange={handleChange}
            className='input'
          />
          {errors.role && <p className={styles.error}>{errors.role}</p>}
        </label>
      </div>

      <div className={styles.formGroup}>
        <label className={styles.label}>
          Current Password:
          <input
            type="password"
            name="currentPassword"
            value={formData.currentPassword}
            onChange={handleChange}
            className='input'
          />
          {errors.currentPassword && <p className={styles.error}>{errors.currentPassword}</p>}
        </label>
      </div>

      <div className={styles.formGroup}>
        <label className={styles.label}>
          New Password:
          <input
            type="password"
            name="newPassword"
            value={formData.newPassword}
            onChange={handleChange}
            className='input'
          />
          {errors.newPassword && <p className={styles.error}>{errors.newPassword}</p>}
        </label>
      </div>

      <div className={styles.formGroup}>
        <label className={styles.label}>
          Confirm New Password:
          <input
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            className='input'
          />
          {errors.confirmPassword && <p className={styles.error}>{errors.confirmPassword}</p>}
        </label>
      </div>

      <div className={styles.formGroup}>
        <button type="submit" className="heroButton">Save Changes</button>
      </div>
    </form>
    </>
  );
};

export default PersonalInformation;
